@import "src/resources/styles/defines.scss";

.header-section {
  position: sticky;
  top: 0;

  z-index: 10;

  width: 100%;

  padding: 0.5rem 2rem;

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }

  background-color: rgba($color-tertiary, 0.9);
  backdrop-filter: blur(2px);

  border-bottom: 3px solid $color-accent;

  overflow-x: clip;

  .header-section__nav {
    display: flex;

    align-items: center;
    justify-content: space-between;

    .header-section__first_block {
      a {
        display: flex;

        img {
          height: 2.5rem;
          pointer-events: none;
        }
      }
    }

    .header-section__toggle {
      display: none;

      text-decoration: none;

      background: none;
      border: none;

      span {
        color: $color-primary;

        font-size: 2rem;
      }
    }

    .header-section__second_block > .header-section__links {
      display: flex;

      gap: 2rem;

      li {
        list-style: none;

        a {
          display: block;

          color: $color-primary;

          font-size: 1.2rem;
          text-decoration: none;

          transition: all 0.1s ease-in;

          &:hover {
            color: $color-accent;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .header-section__toggle {
        display: flex;

        cursor: pointer;
      }

      .header-section__second_block {
        position: fixed;
        top: 0;
        right: 0;

        padding: calc(0.5rem * 2 + 2.5rem + 3px + 1rem) 1rem 1rem 1rem;

        display: flex;

        align-items: flex-start;
        justify-content: flex-start;

        z-index: -1;

        width: 100%;

        background-color: rgba($color-tertiary, 1);
        backdrop-filter: blur(2px);
        border-bottom: 3px solid $color-accent;

        transform: translateY(-80%);

        opacity: 0;

        transition:
                transform 0.5s ease-out,
                opacity 0.5s ease-out;

        &.active {
          transform: translateY(0);

          opacity: 1;
        }

        .header-section__links {
          display: flex;
          flex-direction: column;

          align-items: flex-start;

          gap: 1.5rem;

          li > a {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
