@import "src/resources/styles/defines.scss";

.footer-section {
  .footer-section__content {
    display: flex;
    flex-direction: column;

    justify-content: center;

    padding: 2rem;

    gap: 1rem;

    background: linear-gradient(135deg, $color-tertiary, adjust-color($color-tertiary, $lightness: +10%));

    .footer-section__content__logo {
      height: 4rem;

      img {
        height: 100%;
      }
    }

    .footer-section__content__phone,
    .footer-section__content__email,
    .footer-section__content__address {
      a {
        color: $color-primary;
        text-decoration: none;

        transition: color 0.1s ease-in;

        display: flex;

        align-items: center;
        justify-content: center;

        gap: 0.5rem;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}
