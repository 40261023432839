@import "src/resources/styles/defines.scss";

.info-section {
  padding: 0;

  .info-section__title {
    font-size: 0;
  }

  .info-section__content {
    position: relative;

    display: flex;

    justify-content: center;

    min-height: 70vh;

    img {
      position: absolute;

      z-index: -1;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .info-section__description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 50%;
      box-sizing: border-box;
      padding: 2rem 4rem;

      background: linear-gradient(90deg, rgba(0,0,0,0), rgba($color-tertiary, 0.8) 10%, rgba($color-tertiary, 0.8) 90%, rgba(0,0,0,0));

      overflow: hidden;

      h2 {
        font-size: 2rem;
        font-weight: bold;

        margin: 0 0 1rem 0;
      }

      p {
        margin: 10px 0;

        font-size: 1.15rem;
        font-weight: normal;

        strong {
          color: $color-accent;
        }
      }

      @media (max-width: 1200px) {
        width: 70%;
      }

      @media (max-width: 856px) {
        width: 100%;

        background: rgba($color-tertiary, 0.8);
      }
    }
  }
}
