@import "src/resources/styles/defines.scss";

.product {
  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd0;

  text-align: center;

  transition: all 0.2s ease-in;

  &:hover {
    border-color: #aaaf;

    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }

  .product__image {
    width: 80%;
    margin: 8% 2%;
    aspect-ratio: 1/1;

    border-radius: 100%;

    border: 1px solid $color-tertiary;

    box-shadow: 0 0 10px 4px rgba(0,0,0,0.2);
  }
  .product__name {
    font-size: 1.3rem;
    margin: 0 0 4px 0;
  }

  .product__description {
    font-size: 1.15rem;
    margin: 0 0 10px 0;
  }

  .product__button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #f0c040;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: #f0c040;
    }
  }
}
