@import "src/resources/styles/defines.scss";

.hero-section {
  padding: 0;

  background-color: $color-primary;

  .hero-section__title {
    display: none;
  }

  .hero-section__content {
    position: relative;

    display: flex;
    flex-direction: row;

    min-height: 100vh;

    .hero-section__description {
      position: absolute;

      bottom: 16vh;
      left: 8vw;

      padding: 2rem 3rem;

      border-radius: 30px;

      background-color: rgba($color-tertiary, 0.6);

      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);

      @media (max-width: 520px) {
        left: 5vw;
        right: 5vw;
      }

      h2 {
        font-size: 3rem;
        font-weight: bold;

        text-align: left;

        margin: 0 0 1rem 0;
      }

      p {
        font-size: 1.5rem;
        font-weight: 300;

        text-align: left;

        margin: 0 0 1rem 0;
      }
    }
  }
}
