@import 'defines.scss';

:root {
  background-color: $color-secondary;
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 856px) {
    font-size: 12px;
  }
}

html{
  scroll-behavior:smooth
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: $color-tertiary;
}

section {
  scroll-margin-top: calc(0.5rem * 2 + 2.5rem + 3px);

  padding: 2rem 0;

  border-bottom: 3px solid $color-accent;
}

h1, h2, h3, p {
  font-family: 'Roboto', sans-serif;

  .dark & {
    color: $color-title;
  }

  .light & {
    color: $color-tertiary;
  }

  strong {
    color: $color-accent;
  }
}

img {
  user-select: none;
}
