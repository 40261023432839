@import "src/resources/styles/defines.scss";

.products-section {
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;

  width: 100%;
  height: 100%;

  .products-section__title {
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;

      margin: 0 0 3rem 0;
  }

  .products-section__content {
    display: flex;

    align-items: center;
    justify-content: center;

    gap: 3vw;

    margin: 0 3rem;

    flex-wrap: wrap;

    .product {
      width: 20rem;

      aspect-ratio: 11 / 12;
    }
  }
}
