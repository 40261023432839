@import "src/resources/styles/defines.scss";

.partners-section {
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;

  width: 100%;
  height: 100%;

  .partners-section__title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;

    margin: 0 0 3rem 0;
  }

  .partners-section__content {
    display: flex;

    align-items: stretch;
    justify-content: center;

    gap: 3vh 3vw;

    margin: 0 3rem;

    flex-wrap: wrap;

    a {
      display: flex;

      align-items: center;
      justify-content: center;

      box-shadow: 0 0 5px 0 rgba(black, 0.5);

      border-radius: 10px;

      height: 100px;

      img {
        padding: 10px;

        max-height: 100px;
        max-width: 200px;
      }
    }
  }
}
