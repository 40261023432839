@import 'src/resources/styles/defines.scss';

.exhibitions-section {
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;

  width: 100%;

  background: linear-gradient(135deg, $color-tertiary, adjust-color($color-tertiary, $lightness: +10%));

  .exhibitions-section__title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;

    margin: 0 0 2.5rem 0;
  }

  .swiper {
    max-width: 75vw;
    max-height: 90vh;

    aspect-ratio: 4/3;

    border-radius: 30px;
    border: 3px solid $color-accent;

    .swiper-button-prev,
    .swiper-button-next {
      color: $color-accent;
    }

    --swiper-pagination-bullet-inactive-opacity: 0.5;

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: black;
      }

      .swiper-pagination-bullet-active {
        background-color: $color-accent;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
